<template>
  <div class="bagManagement content-index">
    <el-page-header @back="goBack">
      <template slot="title"
        ><span style="color:#101010;font-size:16px">返回</span></template
      >
    </el-page-header>
    <div v-loading="loading" style="width: 1340px;">
       <div class="content-box">
      <div
        class="box-item"
        style="width:600px"
        v-for="(item, index) in logisticsBagsData &&
        logisticsBagsData.length != 0
          ? logisticsBagsData.slice(0, 1)
          : []"
        :key="index"
      >
        <!-- 上半部分标题 -->
        <div class="item-title">
          {{ item.keyName
          }}<span style="font-size:16px;font-weight: 700;">{{
            item.bagsNum
          }}</span
          >个
        </div>
        <!-- 上半部分内容 -->
        <div>
          <div
            class="item-top-content"
            v-if="item.colorNumList && item.colorNumList.length != 0"
          >
            <span
              v-for="(item2, index2) in item.colorNumList"
              :key="index2"
              class="content-left"
              :style="
                item.colorNumList.length - 1 == index2
                  ? ''
                  : 'border-right:1px solid rgb(235, 238, 245)'
              "
              >{{ item2.keyName
              }}<span style="font-size:16px;font-weight: 700;">{{
                item2.bagsNum
              }}</span
              >个
            </span>
          </div>
          <div class="item-top-content" v-else>
            <span class="content-left">
              暂无数据
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="icon-line" v-if="logisticsBagsData &&
        logisticsBagsData.length != 0">
      <div class="dot"></div>
      <div class="line4"></div>
      <div class="line5"></div>
      <div class="line6"></div>
      <div class="line7"></div>
      <div
        class="triangle"
        style="transform: rotate(76deg);
    left: 162px;"
      ></div>
       <div
        class="triangle"
        style="transform: rotate(55deg);
    left: 496px;"
      ></div>
       <div
        class="triangle"
        style="transform: rotate(-55deg);
    right: 496px;"
      ></div>
       <div
        class="triangle"
        style="transform: rotate(-76deg);
    right: 162px;"
      ></div>
    </div>
    <div class="content-box" >
      <div
        class="box-item"
        v-for="(item, index) in logisticsBagsData &&
        logisticsBagsData.length != 0
          ? logisticsBagsData.slice(1, logisticsBagsData.length)
          : []"
        :key="index"
      >
        <!-- 上半部分标题 -->
        <div class="item-title">
          {{ item.keyName
          }}<span style="font-size:16px;font-weight: 700;">{{
            item.bagsNum
          }}</span
          >个
        </div>
        <!-- 上半部分内容 -->
        <div>
          <div
            class="item-top-content"
            v-if="item.colorNumList && item.colorNumList.length != 0"
          >
            <span
              v-for="(item2, index2) in item.colorNumList"
              :key="index2"
              class="content-left"
              :style="
                item.colorNumList.length - 1 == index2
                  ? ''
                  : 'border-right:1px solid rgb(235, 238, 245)'
              "
              >{{ item2.keyName
              }}<span style="font-size:16px;font-weight: 700;">{{
                item2.bagsNum
              }}</span
              >个
            </span>
          </div>
          <div class="item-top-content" v-else>
            <span class="content-left">
              暂无数据
            </span>
          </div>
        </div>
        <div
          class="icon"
          v-if="item.factoryNumList && item.factoryNumList.length != 0"
        >
          <!-- 小圆点 -->
          <span class="dot"></span>
          <div style="height:90px;overflow:hidden;">
            <div class="line" v-if="item.factoryNumList.length == 2"></div>
            <div class="line2" v-if="item.factoryNumList.length == 1"></div>
            <div class="line2" v-if="item.factoryNumList.length == 3"></div>
            <div class="line3" v-if="item.factoryNumList.length == 3"></div>
          </div>
          <span
            class="triangle"
            v-if="item.factoryNumList.length == 2"
            style="transform: translateX(76px);"
          ></span>
          <span
            class="triangle"
            v-if="item.factoryNumList.length == 2"
            style="transform: translateX(234px);"
          ></span>
          <span
            class="triangle"
            v-if="item.factoryNumList.length == 1"
            style="transform: translateX(155px);"
          ></span>
          <span
            class="triangle"
            v-if="item.factoryNumList.length == 3"
            style="transform: translateX(155px);"
          ></span>
          <span
            class="triangle"
            v-if="item.factoryNumList.length == 3"
            style="transform: translateX(52px);"
          ></span>
          <span
            class="triangle"
            v-if="item.factoryNumList.length == 3"
            style="transform: translateX(258px);"
          ></span>
        </div>
        <!-- 下半部分左边 -->
        <div class="item-bottom-content">
          <div
            class="bottom-item"
            style="margin-right:1px"
            v-for="(item3, index3) in item.factoryNumList"
            :key="index3"
          >
            <div class="item-title">
              {{ item3.keyName }}
            </div>
            <div class="content-bottom">
              <span
                v-for="(item4, index4) in item3.factoryColorNumList"
                :key="index4"
              >
                {{ item4.keyName
                }}<span style="font-size:16px;font-weight: 700;">{{
                  item4.bagsNum
                }}</span
                >个</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
   
  </div>
</template>

<script>
import { getLogisticsStoreColorBagsNum } from "@/api/clothes/order";
export default {
  name: "bagManagement",
  data() {
    return {
      logisticsBagsData: [],
      loading: false,
    };
  },
  created() {
    this.getLogisticsStoreColorBagsNumData();
  },
  methods: {
    // 获取门店袋子数据
    getLogisticsStoreColorBagsNumData() {
      this.loading = true;
      getLogisticsStoreColorBagsNum()
        .then(({ data }) => {
          if (data.code == 0) {
            this.logisticsBagsData = data.data
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.bagManagement {
  padding: 20px;
  overflow:auto;
  ::v-deep .el-page-header__left::after {
    width: 0;
  }
  .content-box {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    .box-item {
      width: 320px;
      .item-title {
        height: 45px;
        background-color: #3370ff;
        text-align: center;
        color: #fff;
        font-size: 14px;
        line-height: 45px;
      }
      .item-top-content {
        height: 80px;
        font-size: 14px;
        border: 1px solid rgb(235, 238, 245);
        display: flex;
        flex: 1;
        .content-left,
        .content-right {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .item-bottom-content {
        font-size: 14px;

        display: flex;
        .bottom-item {
          border: 1px solid rgb(235, 238, 245);
          flex: 1;
        }
        .content-bottom {
          height: 80px;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }
      .icon {
        height: 100px;
        width: 100%;
        position: relative;
        overflow: hidden;
        .dot {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: red;
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .line {
          margin: 8px auto 0;
          height: 200px;
          width: 160px;
          border-radius: 100px;
          border: 2px dashed red;
        }
        .line2 {
          width: 1px;
          height: 80px;
          margin: 20px auto 0;
          border-left: 2px dashed red;
          background-color: rgba(255, 0, 0, 0);
        }
        .line3 {
          height: 200px;
          width: 210px;
          border-radius: 100px;
          border: 2px dashed red;
          position: absolute;
          top: 8px;
          left: 50%;
          transform: translateX(-50%);
        }

        .triangle {
          position: absolute;
          width: 0;
          height: 0;
          border-top: 10px solid red;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
        }
      }
    }
  }
  .icon-line {
    height: 100px;
    width: 100%;
    position: relative;
    .dot {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: red;
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .triangle {
      position: absolute;
      bottom: -28px;
      width: 0;
      height: 0;
      border-top: 10px solid red;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
    .line4 {
      width: 516px;
    height: 1px;
    border-bottom: 2px dashed red;
    background-color: rgba(255, 0, 0, 0);
    position: absolute;
    bottom: 36px;
    left: 166px;
    transform: rotate(-13deg);
    }
    .line5 {
          width: 196px;
    height: 1px;
    border-bottom: 2px dashed red;
    background-color: rgba(255, 0, 0, 0);
    position: absolute;
    bottom: 33px;
    left: 487px;
    transform: rotate(-34deg);
    }
    .line6 {
          width: 196px;
    height: 1px;
    border-bottom: 2px dashed red;
    background-color: rgba(255, 0, 0, 0);
    position: absolute;
    bottom: 33px;
    right: 487px;
    transform: rotate(34deg);
    }
    .line7 {
      width: 516px;
    height: 1px;
    border-bottom: 2px dashed red;
    background-color: rgba(255, 0, 0, 0);
    position: absolute;
    bottom: 36px;
    right: 166px;
    transform: rotate(13deg);
    }
  }
}
</style>
